import axios from "axios";
import { BASE_API_URL } from "config";

export interface ICodeResponse {
  device_code: string;
  user_code: string;
  verification_uri: string;
  expires_in: number;
  interval: number;
  verification_uri_complete: string;
}

export const getDeviceCode = async () => {
  const result = await axios.get<ICodeResponse>(
    `${BASE_API_URL}/livly/view/code`
  );

  return result.data;
};

export interface ITokenResponse {
  Expiraton_date: string;
  access_token: string;
  expires_in: number;
  error?: string;
  error_description?: string;
  refresh_token: string | null;
}

export const getAccessToken = async (deviceCode: string) => {
  const result = await axios.get<ITokenResponse>(
    `${BASE_API_URL}/livly/view/${deviceCode}/token/`
  );

  return result;
};

export const refreshAccessToken = async (refresh_token: string) => {
  const result = await axios.post<ITokenResponse>(
    `${BASE_API_URL}/livly/view/refreshToken`,
    `"${refresh_token}"`,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  return result.data;
};
