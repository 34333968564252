import React, { useEffect, useState } from "react";

const Clock = () => {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    setInterval(() => {
      setValue(new Date());
    }, 1000);
  }, []);

  const month = value.getMonth();
  const day = value.getDay();
  const date = value.getDate();
  const hour = value.getHours();
  const minutes = value.getMinutes();
  const isAntiMeridiem = hour < 12;

  return (
    <div className="px-8">
      <div>
        <h3 className="text-27">
          {days[day]}, {months[month]} {date}
        </h3>
      </div>
      <div className="flex items-baseline">
        <div className="pr-4">
          <h3 className="md:text-[70px] 2xl:text-90">
            {hour > 12 ? hour - 12 : hour}:
            {minutes < 10 ? `0${minutes}` : minutes}
          </h3>
        </div>
        <h3>{isAntiMeridiem ? "am" : "pm"}</h3>
      </div>
    </div>
  );
};

export default Clock;

const months: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const days: string[] = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
