import { IWeather } from "data/weather";
import React from "react";
import WeatherNow from "./WeatherNow";
import WeatherHour from "./WeatherHour";
import moment from "moment";

type Props = {
  bannerUrl: string | null;
  weather: IWeather | undefined;
};

export const WeatherHeader = ({ bannerUrl, weather }: Props) => {
  return (
    <header className="landscape:hidden">
      <div className="flex justify-between">
        <div className="w-1/3 flex items-center justify-center p-8">
          <img src={bannerUrl || ""} alt="building" />
        </div>
        {weather && (
          <div className=" p-8">
            <div className="flex items-center p-8 shadow-livly rounded-xl">
              <WeatherNow
                high={weather.daily.high}
                low={weather.daily.low}
                code={weather.daily.code}
              />
              <div>
                {weather.hourly.slice(0, 3).map((hour) => (
                  <WeatherHour
                    key={hour.dateTime}
                    time={moment(hour.dateTime).format("h:mm a")}
                    temperature={Math.floor(hour.temperature)}
                    code={hour.code}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};
