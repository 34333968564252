import { IWeather } from "data/weather";
import React, { useEffect, useState } from "react";
import WeatherNow from "./WeatherNow";
import WeatherHour from "./WeatherHour";
import moment from "moment";
import Clock from "./Clock";
import LogoQRCode from "utils/LogoQRCode";
import styled from "styled-components";
import Scrible from "../assets/Scribble_bg.svg";

const BGContainer = styled.div`
  background-image: url("/assets/download_bg_horizontal.png");
`;

type Props = {
  bannerUrl: string | null;
  weather: IWeather | undefined;
  logoUrl: string;
  brandBranchLink: string | null;
  brandName: string | null;
};
export const WeatherAside = ({
  bannerUrl,
  weather,
  logoUrl,
  brandBranchLink,
  brandName
}: Props) => {
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    if (logoUrl) {
      setOptions({
        ecLevel: "M", //The error correction level of the QR Code
        enableCORS: false, //Enable crossorigin attribute
        size: 90, //The size of the QR Code
        quietZone: 10, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
        //Foreground color
        logoImage: logoUrl,
        logoWidth: 100 * 0.2,
        logoHeight: 100 * 0.2,
        logoOpacity: 1,
        qrStyle: "dots",
        removeQrCodeBehindLogo: true
      });
    }
  }, [logoUrl]);

  return (
    <aside className="portrait:hidden w-1/4 flex flex-col p-10">
      <img
        className="md:w-48 2xl:w-56 mx-auto md:mb-8 2xl:mb-12"
        src={bannerUrl || ""}
        alt="building"
      />
      <div className="mb-10">
        {weather && (
          <div className="portrait:flex portrait:p-8 portrait:shadow-livly portrait:rounded-xl">
            <WeatherNow
              high={weather.daily.high}
              low={weather.daily.low}
              code={weather.daily.code}
            />
            <div className="landscape:grid landscape:grid-cols-3 landscape:gap-4">
              {weather.hourly.slice(0, 3).map((hour) => (
                <WeatherHour
                  key={hour.dateTime}
                  temperature={Math.floor(hour.temperature)}
                  time={moment(hour.dateTime).format("h:mm a")}
                  code={hour.code}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="my-auto">
        <BGContainer className="bg-cover bg-no-repeat bg-center w-full h-full rounded-xl shadow-livly flex justify-center items-center">
          <div className="flex flex-col justify-between items-center 2xl:w-[70%] my-4">
            <div>
              <img src={logoUrl} alt="logo" width={30} height={30} />
            </div>
            <div className=" relative mt-2 border-b border-gray-300 pb-4 w-full">
              <h1 className="md:text-lg 2xl:text-2xl text-center">
                Download the{" "}
                <span className="relative  z-[999]">{brandName}</span>
                <br /> property app
              </h1>
              <span className="absolute top-1 right-7 2xl:right-16 z-10">
                <img src={Scrible} alt="bg-logo" width={50} />
              </span>
            </div>
            <div className="flex justify-between items-center w-full">
              <p className="md:text-sm 2xl:text-base font-light mr-4">
                Real life convenience
                <br /> and community for
                <br /> residents and buildings.
              </p>
              <LogoQRCode url={brandBranchLink} options={options} />
            </div>
          </div>
        </BGContainer>
      </div>
      <div className="mt-auto">
        <Clock />
      </div>
    </aside>
  );
};
