import React from "react";

export default function PageTitle({
  title,
  noLeftPadding
}: {
  title: string;
  noLeftPadding?: boolean;
}) {
  return (
    <h1
      className={`container mx-auto ${
        !noLeftPadding ? "pl-24" : "pl-10 ml-0"
      } py-8`}
    >
      {title}
    </h1>
  );
}
