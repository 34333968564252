import React from "react";
import styled from "styled-components";

const Container = styled.div<{
  url: "feed" | "event" | "amenities" | "package";
}>`
  @media (orientation: portrait) {
    background-image: url(${(props) => `/assets/vertical_bg_${props.url}.png`});
  }
  @media (orientation: landscape) {
    background-image: url(${(props) =>
      `/assets/horizontal_bg_${props.url}.png`});
  }
`;

const Background: React.FC<{
  url: "feed" | "event" | "amenities" | "package";
}> = ({ url, children }) => {
  return (
    <Container url={url} className="h-full bg-cover bg-no-repeat bg-center">
      {children}
    </Container>
  );
};

export default Background;
