import axios from "axios";

import { DEVICE_CODE, ACCESS_TOKEN } from "config";

axios.interceptors.response.use(
  (resp) => resp,
  (error: any) => {
    if (error.response.status === 401) {
      localStorage.removeItem(DEVICE_CODE);
      localStorage.removeItem(ACCESS_TOKEN);

      window.location.reload();
    }
  }
);
