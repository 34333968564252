import React from "react";

const Logo = () => {
  return (
    <div className="flex justify-end mt-auto p-4 landscape:invisible">
      <img src="/assets/logo.svg" alt="livly view" />
    </div>
  );
};

export default Logo;
