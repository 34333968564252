import axios from "axios";
import { ACCESS_TOKEN, BASE_API_URL, DEVICE_CODE } from "config";

export interface IEvent {
  eventId: number;
  title: string;
  description: string;
  imageUrl: string;
  location: string;
  startDate: string;
  endDate: string;
  isCanceled: boolean;
}

export const getEvents = async () => {
  const deviceCode = localStorage.getItem(DEVICE_CODE);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  const result = await axios.get<IEvent[]>(
    `${BASE_API_URL}/livly/view/${deviceCode}/events`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return result.data;
};
