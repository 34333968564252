import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { IConfiguration } from "data/configuration";
import { getUser } from "data/user";

import { DEVICE_CODE } from "config";
import useGetConfiguration from "hooks/useGetConfiguration";
import rg4js from "raygun4js";

const Admin = () => {
  const deviceCode = localStorage.getItem(DEVICE_CODE);

  const history = useHistory();
  const client = useQueryClient();

  const { isLoading: isFetchingUser } = useQuery("user", getUser, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      rg4js("setUser", {
        identifier: data.id,
        isAnonymous: false,
        email: data.email,
        firstName: data.first_name,
        fullName: `${data.first_name} ${data.last_name}`,
      });
    },
    onError: (e) => {
      rg4js("send", e);
    },
  });

  const onSuccess = (data: IConfiguration) => {
    if (data.message === null) {
      client.invalidateQueries("configuration");
      history.replace("/");
    }
  };

  useGetConfiguration(onSuccess, null, 1000 * 10);

  if (isFetchingUser) {
    return (
      <div className="container mx-auto">
        <p>Fetching user...</p>
      </div>
    );
  }

  return (
    <div className="h-screen flex items-center justify-center bg-pebbles">
      <div className="p-12 shadow-livly rounded-xl bg-white">
        <div className="mb-6 flex justify-center">
          <img src="/assets/logo.svg" alt="livly view" />
        </div>
        <div className="mb-12">
          <h2 className="mb-4">
            Follow the directions below to finish the assignment your Livly
            View:
          </h2>
          <ol className="list-decimal text-3xl pl-4 space-y-4">
            <li>Visit hub.livly.io</li>
            <li>
              Click on the TV icon in the bottom left-hand corner of the Livly
              Hub Dashboard
            </li>
            <li>Navigate to the unassigned section at the top of the page</li>
            <li>
              Locate the ID number that matches the one displayed on this screen
            </li>
            <li>
              Click on the assign screen button and choose the property
              associated with this device
            </li>
            <li>Click save to finish the setup of your Livly View</li>
          </ol>
        </div>
        <div>
          <p className="mb-2 text-3xl">Screen ID:</p>
          <p className="font-bold text-7xl cursor-pointer">{deviceCode}</p>
        </div>
      </div>
    </div>
  );
};

export default Admin;
