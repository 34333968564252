import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IEvent } from "data/events";

import Card from "components/Card";
import Logo from "components/Logo";
import PageTitle from "components/PageTitle";
import toLocalTime from "utils/toLocalTime";

type Props = { data?: IEvent };

const Event = ({ data }: Props) => {
  const { title, imageUrl, description, location, startDate } = data!;

  const eventDescription = getEventDescription(description);

  return (
    <div className="flex flex-col landscape:h-screen portrait:h-full">
      <PageTitle title="Event" />

      <div className="flex max-h-full p-24 pt-0 overflow-auto">
        <Card>
          <div className="grid grid-cols-12 gap-8">
            <div className="col-span-6">
              <h2 className="mb-10">{title}</h2>
              <div className="flex items-center mb-4">
                <div className="w-10 mr-5 text-center">
                  <FontAwesomeIcon icon={["fal", "map-marker-alt"]} size="2x" />
                </div>
                <p className="body1">{location}</p>
              </div>
              <div className="flex items-center mb-4">
                <div className="w-10 mr-5 text-center">
                  <FontAwesomeIcon icon={["fal", "clock"]} size="2x" />
                </div>
                <p className="body1">
                  {toLocalTime(startDate).format("dddd MMMM D, YYYY")}
                </p>
              </div>
              <div className="flex-1 max-h-96">
                <div dangerouslySetInnerHTML={{ __html: eventDescription }} />
              </div>
            </div>
            <div className="flex items-center col-span-6 mx-0 max-w-none">
              <div className="w-full max-w-md mx-auto rounded-lg shadow-lg">
                <img className="w-full" src={imageUrl} alt={title} />
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Logo />
    </div>
  );
};

export default Event;

function getEventDescription(description: string) {
  try {
    const eventDescription = decodeURIComponent(description);

    return eventDescription;
  } catch (e) {}

  return description;
}
