import React from "react";

type Props = { avatarUri: string; primary: string; secondary: string };

const AvatarDetails = ({ avatarUri, primary, secondary }: Props) => {
  const [first, second] = primary.split(" ");

  return (
    <div className="flex mb-10">
      <div className="w-24 h-24 relative mr-6">
        <div className="group w-full h-full rounded-full overflow-auto shadow-inner text-center bg-purple flex justify-center items-center bg-bugs bg-opacity-10">
          {avatarUri ? (
            <img
              src={avatarUri}
              alt={primary}
              className="object-cover object-center w-full h-full"
            />
          ) : (
            <span className="font-bold text-4xl">
              {first[0]}
              {second[0]}
            </span>
          )}
        </div>
      </div>
      <div>
        <h2>{primary}</h2>
        <h3>{secondary}</h3>
      </div>
    </div>
  );
};

export default AvatarDetails;
