import React, { useEffect, useState } from "react";
import Clock from "./Clock";
import LogoQRCode from "utils/LogoQRCode";
import styled from "styled-components";
import Scrible from "../assets/Scribble_bg.svg";

const BGContainer = styled.div`
  background-image: url("/assets/download_bg_verticle.png");
`;

type Props = {
  logoUrl: string;
  brandBranchLink: string | null;
  brandName: string | null;
};

export const PotraitFooter = ({
  logoUrl,
  brandBranchLink,
  brandName
}: Props) => {
  const [options, setOptions] = useState<any>();

  useEffect(() => {
    if (logoUrl) {
      setOptions({
        ecLevel: "M", //The error correction level of the QR Code
        enableCORS: false, //Enable crossorigin attribute
        size: 100, //The size of the QR Code
        quietZone: 10, //The size of the quiet zone around the QR Code. This will have the same color as QR Code bgColor
        //Foreground color
        logoImage: logoUrl,
        logoWidth: 100 * 0.2,
        logoHeight: 100 * 0.2,
        logoOpacity: 1,
        qrStyle: "dots",
        removeQrCodeBehindLogo: true
      });
    }
  }, [logoUrl]);

  return (
    <footer className="landscape:hidden">
      <div className="flex justify-between items-center p-10">
        <Clock />

        <BGContainer
          className="bg-cover bg-no-repeat bg-center w-full 
        h-full rounded-xl shadow-livly min-w-[600px] min-h-[200px] flex justify-center items-center "
        >
          <div className="flex justify-between items-center w-[90%] py-4">
            <div className="flex w-full justify-center items-center">
              <div className="border-r border-gray-300  w-2/4">
                <img
                  src={logoUrl}
                  alt="logo"
                  width={50}
                  height={50}
                  className="ml-2"
                />
                <div className="relative mt-2 pb-4 w-full">
                  <h1 className="text-3xl text-left">
                    Download the{" "}
                    <span className="relative  z-[999]">{brandName}</span>
                    <br /> property app
                  </h1>
                  <span className="absolute top-2 xl:right-40 md:right-32 z-10">
                    <img src={Scrible} alt="bg-logo" width={50} />
                  </span>
                </div>
              </div>
              <div className="w-2/5 flex justify-center flex-col items-center">
                <LogoQRCode url={brandBranchLink} options={options} />
                <p className="text-base font-light mt-4 text-center">
                  Real life convenience and <br />
                  community for residents and <br />
                  buildings.
                </p>
              </div>
            </div>
          </div>
        </BGContainer>
      </div>
    </footer>
  );
};
