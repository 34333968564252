import { PackageItem } from "components/PackageItem";
import { PackageProps } from "components/PackageScreen";
import PageTitle from "components/PageTitle";
import React, { useEffect, useMemo, useState } from "react";

export const Packages = ({
  data,
  expand,
  setExpand
}: {
  data: PackageProps[];
  expand: boolean;
  setExpand: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const isPortrait = () => window.matchMedia("(orientation: portrait)").matches;
  const [portrait, setPortrait] = useState(isPortrait() ? true : false);

  const onWindowResize = () => {
    clearTimeout((window as any).resizeLag);
    (window as any).resizeLag = setTimeout(() => {
      delete (window as any).resizeLag;
      setPortrait(isPortrait() ? true : false);
    }, 200);
  };
  useEffect(
    () => (
      // eslint-disable-next-line no-sequences
      onWindowResize(),
      window.addEventListener("resize", onWindowResize),
      () => window.removeEventListener("resize", onWindowResize)
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const splitToSubArray: ({
    arr,
    size
  }: {
    arr: PackageProps[];
    size: number;
  }) => any = ({ arr, size }: { arr: any[]; size: number }) =>
    arr.length > size
      ? [arr.slice(0, size), ...splitToSubArray({ arr: arr.slice(size), size })]
      : [arr];

  const subPackages = useMemo(() => {
    return splitToSubArray({ arr: data, size: portrait ? 30 : 20 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portrait, data]);

  return (
    <div className="landscape:h-screen portrait:h-full flex flex-col">
      <PageTitle title="Packages" noLeftPadding={true} />
      <div className="p-10 pt-0 overflow-auto flex flex-col  max-h-screen h-auto">
        {subPackages?.length > 1 ? (
          <div className="flex gap-8">
            {subPackages.map((chunk: PackageProps[], i: number) => (
              <div
                className={`${
                  portrait ? "w-[45%]" : "w-[30%]"
                } h-full bg-white shadow p-8 rounded`}
                key={i}
              >
                {chunk.map((pkg: PackageProps, index: number) => (
                  <div
                    key={index}
                    className={`p-2 flex ${
                      chunk.length !== index + 1 && "border-b"
                    } justify-between items-center`}
                  >
                    <PackageItem
                      key={index}
                      pkg={pkg}
                      expand={expand}
                      setExpand={() => setExpand(!expand)}
                      width="md:w-[30%] lg:w-[30%] xl:w-[38%] 2xl:w-[45%] portrait:w-[35%] "
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          subPackages?.map((chunk: PackageProps[], i: number) => (
            <div className="w-[95%] h-full bg-white shadow p-8 rounded" key={i}>
              {chunk.map((pkg: PackageProps, index: number) => (
                <div
                  key={index}
                  className={`p-2 2xl:pb-3 flex ${
                    chunk.length !== index + 1 && "border-b"
                  } justify-between items-center`}
                >
                  <PackageItem
                    key={index}
                    pkg={pkg}
                    expand={expand}
                    setExpand={() => setExpand(!expand)}
                    width="w-[15%]"
                    largeView={true}
                  />
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
