import React, { useState, useEffect } from "react";

import { useTransition, animated } from "react-spring";

import Background from "components/Background";

import { Packages } from "views/Packages";

type Props = {
  panels: { id: number; type: "package"; data: any }[];
};

const PackagePanels = ({ panels }: Props) => {
  const [index, set] = useState(0);
  const [expand, setExpand] = useState(false);
  let interval = React.useRef<any>(0);

  const panelsRef = React.useRef(panels.length);

  const transitions = useTransition(panels[index], (item) => item?.id, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" }
  });

  useEffect(() => {
    if (panels.length > 1) {
      panelsRef.current = panels.length;
      clearInterval(interval.current);
      interval.current = setInterval(() => {
        set((state) => (state + 1) % panelsRef.current);
      }, 10000);
    }
  }, [panels]);

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        return (
          <animated.div
            key={key}
            className="absolute top-0 left-0 bottom-0 w-full"
            style={{
              ...props
            }}
          >
            <Background url={item.type}>
              <Packages
                data={item.data}
                expand={expand}
                setExpand={setExpand}
              />
            </Background>
          </animated.div>
        );
      })}
    </>
  );
};

export default PackagePanels;
