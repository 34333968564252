import React from "react";
import { PackageProps } from "./PackageScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PackageItem = ({
  pkg,
  expand,
  setExpand,
  width,
  largeView = false
}: {
  pkg: PackageProps;
  expand: boolean;
  setExpand: () => void;
  width: string;
  largeView?: boolean;
}) => {
  return (
    <>
      <p
        className={`font-semibold ${
          largeView
            ? "md:text-xs 2xl:text-[20px] portrait:text-[20px]"
            : "md:text-xs 2xl:text-[20px]  portrait:text-[20px]"
        }`}
      >
        {pkg.name}
      </p>
      <div
        className={`flex cursor-pointer ${
          expand && `justify-start items-center  ${width}`
        }`}
        onClick={setExpand}
      >
        {pkg.total > 1 ? (
          <FontAwesomeIcon
            className={`text-gray-500 ${
              largeView ? "md:text-xs 2xl:text-3xl" : "md:text-xs 2xl:text-2xl"
            }`}
            icon={["fal", "boxes"]}
          />
        ) : (
          <FontAwesomeIcon
            className={`text-gray-500 ${
              largeView ? "md:text-xs 2xl:text-3xl" : "md:text-xs 2xl:text-2xl"
            }`}
            icon={["fal", "box"]}
          />
        )}
        {expand && (
          <p
            className={`text-gray-500 ${
              largeView ? "md:text-xs 2xl:text-3xl" : "md:text-xs 2xl:text-base"
            } ml-4`}
          >{`${pkg.total > 1 ? "1+ packages" : "1 package"}`}</p>
        )}
      </div>
    </>
  );
};
