import axios from "axios";

import { ACCESS_TOKEN, BASE_API_URL, DEVICE_CODE } from "config";

export interface IConfiguration {
  bannerUrl: string | null;
  brandBranchLink: string | null;
  brandIcon: string | null;
  brandName: string | null;
  displayName: string | null;
  message: string;
  type: ViewTypeEnum | null;
  propertyId: number | null;
  version: string | null;
  viewPanels: ("Weather" | "Announcements" | "Amenities" | "Events")[];
}

export enum ViewTypeEnum {
  Lobby = 0,
  Package = 1
}

export const postConfiguration = async (
  livlyClientId: number,
  propertyId: number
) => {
  const deviceCode = localStorage.getItem(DEVICE_CODE);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  return await axios.post(
    `${BASE_API_URL}/livly/view/clients/${livlyClientId}/properties/${propertyId}/${deviceCode}`,
    null,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );
};
