import axios from "axios";
import { ACCESS_TOKEN, BASE_API_URL, DEVICE_CODE } from "config";

export interface IAmenity {
  amenitySpaceId: number;
  name: string;
  description: string;
  images: {
    uri?: string;
  }[];
  slots: {
    startTime: string;
    endTime: string;
    availableSlotText: string;
    availabilityType: number;
    availabilityTypeString: string;
  }[];
  bookingAvailabilityType: "Open" | "Daily" | "Overnight";
}

export const getAmenities = async () => {
  const deviceCode = localStorage.getItem(DEVICE_CODE);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  const result = await axios.get<IAmenity[]>(
    `${BASE_API_URL}/livly/view/${deviceCode}/amenities`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return result.data;
};
