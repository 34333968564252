import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconCodeMap } from "icons";

type Props = { time: string | Date; temperature: number; code: number };

const WeatherHour = ({ time, temperature, code }: Props) => {
  return (
    <div className="landscape:flex landscape:flex-1 landscape:flex-col portrait:items-center md:landscape:p-2  2xl:landscape:p-6 landscape:pb-4 landscape:shadow-livly landscape:rounded-xl landscape:items-center portrait:grid portrait:grid-cols-3 portrait:gap-2">
      <p className="md:text-[10px] 2xl:text-17 portrait:text-right">{time}</p>
      <h3 className="md:text-lg 2xl:text-3xl landscape:order-last portrait:text-center">
        {temperature}°
      </h3>
      <FontAwesomeIcon
        className="landscape:my-4"
        style={IconCodeMap[code].style}
        icon={IconCodeMap[code].icon}
        size="2x"
      />
    </div>
  );
};

export default WeatherHour;
