import axios from "axios";
import { ACCESS_TOKEN, BASE_API_URL, DEVICE_CODE } from "config";

export interface IPackages {
  PropertyId: number;
  count: number;
  byUnit: MUnitType;
}

interface MUnitType {
  [name: number]: number;
}

export const getPackages = async () => {
  const deviceCode = localStorage.getItem(DEVICE_CODE);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  const result = await axios.get<IPackages>(
    `${BASE_API_URL}/livly/view/${deviceCode}/packages`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return result.data;
};
