import axios, { AxiosRequestConfig } from "axios";

import { ACCESS_TOKEN, EXPIRATION_DATE, REFRESH_TOKEN } from "config";
import { refreshAccessToken } from "../data/activation";

const baseURL = process.env.REACT_APP_BASE_API_URL as string;

const getAuthHeaderValue = async () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN);
  const expirationDateString = localStorage.getItem(EXPIRATION_DATE);
  const currentAccessToken = localStorage.getItem(ACCESS_TOKEN);

  if (expirationDateString) {
    const expirationDate = new Date(expirationDateString);

    //check if token is expired
    if (expirationDate > new Date()) {
      return `Bearer ${currentAccessToken}`;
    }
  }

  const result = await refreshAccessToken(refreshToken!);
  const { access_token, Expiraton_date } = result;

  localStorage.setItem(ACCESS_TOKEN, access_token);
  localStorage.setItem(EXPIRATION_DATE, Expiraton_date);

  // If user is logged in, get the token from the login provider.
  return `Bearer ${access_token}`;
};

export default function useMakeAuthedRequest() {
  /**
   * Async function for sending request authorized with Auth0
   *
   * @param {Object} request object created by requestFactory.
   * @returns {Object} request response data as a Promise.
   */
  async function makeAuthedRequest<T>(request: Partial<AxiosRequestConfig>) {
    const { data } = await axios.request<T>({
      baseURL,
      ...request,
      headers: {
        ...request.headers,
        // Add the Authorization header to the existing headers
        Authorization: await getAuthHeaderValue(),
      },
    });

    return data;
  }

  return makeAuthedRequest;
}
