import axios from "axios";

import { ACCESS_TOKEN, BASE_API_URL, DEVICE_CODE } from "config";

export interface IAnnouncement {
  id: string;
  actorId: number;
  actorUri: string;
  actorName: string;
  timestamp: string;
  textBody: string;
  images: {
    uri: string;
    width: number;
    height: number;
  }[];
}

export const getAnnouncements = async (
) => {
  const deviceCode = localStorage.getItem(DEVICE_CODE);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  
  const result = await axios.get<IAnnouncement[]>(
    `${BASE_API_URL}/livly/view/${deviceCode}/announcements`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return result.data;
};
