import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IconCodeMap } from "icons";

type Props = { high: number; low: number; code: number };

const WeatherNow = ({ high, low, code }: Props) => {
  return (
    <div className="portrait:flex-1 landscape:pb-4 landscape:justify-center portrait:mr-8">
      <div className="flex items-center landscape:p-10 landscape:pb-6 landscape:shadow-livly landscape:rounded-lg">
        <div className="flex-shrink-0 md:pr-4 2xl:pr-10">
          <FontAwesomeIcon
            style={{ ...IconCodeMap[code].style, fontSize: 64 }}
            icon={IconCodeMap[code].icon}
          />
        </div>
        <div>
          <p className="md:text-sm 2xl:text-17 portrait:pr-4">Today</p>
          <div className="flex items-baseline">
            <p className="md:text-[50px] 2xl:text-71">{Math.floor(high)}°</p>
            <h3 className="md:text-[20px] 2xl:text-31">/{Math.floor(low)}°</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherNow;
