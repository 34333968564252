import React, { useContext } from "react";
import { useQuery } from "react-query";

import Panels from "./Panels";
import { ConfigurationContext } from "views/Home";

import { getWeather } from "data/weather";
import { getAnnouncements } from "data/announcements";
import { getEvents } from "data/events";
import { getAmenities } from "data/amenities";

import rg4js from "raygun4js";
import { WeatherHeader } from "./WeatherHeader";
import { WeatherAside } from "./WeatherAside";
import { PotraitFooter } from "./PotraitFooter";

const chunkSize = 3;

const LobbyScreen = () => {
  const { bannerUrl, brandBranchLink, brandName, brandIcon, viewPanels } =
    useContext(ConfigurationContext);

  const { data: weather } = useQuery("view-weather", getWeather, {
    refetchInterval: 1000 * 60 * 31,
    refetchOnWindowFocus: false,
    retryDelay: 1000 * 30,
    onError: (e) => {
      rg4js("send", e);
    }
  });

  const { data: announcements = [], isLoading: isLoadingAnnouncements } =
    useQuery("view-announcements", getAnnouncements, {
      enabled: viewPanels.includes("Announcements"),
      refetchInterval: 1000 * 60 * 31,
      refetchOnWindowFocus: false,
      retryDelay: 1000 * 30,
      onError: (e) => {
        rg4js("send", e);
      }
    });

  const { data: events = [], isLoading: isLoadingEvents } = useQuery(
    "view-events",
    getEvents,
    {
      enabled: viewPanels.includes("Events"),
      refetchInterval: 1000 * 60 * 31,
      refetchOnWindowFocus: false,
      retryDelay: 1000 * 30,
      onError: (e) => {
        rg4js("send", e);
      }
    }
  );

  const { data: amenities = [], isLoading: isLoadingAmenities } = useQuery(
    "view-amenities",
    getAmenities,
    {
      enabled: viewPanels.includes("Amenities"),
      refetchInterval: 1000 * 60 * 31,
      refetchOnWindowFocus: false,
      retryDelay: 1000 * 30,
      onError: (e) => {
        rg4js("send", e);
      }
    }
  );

  const isLoading =
    isLoadingAmenities || isLoadingAnnouncements || isLoadingEvents;

  const panels = React.useMemo(() => {
    if (isLoading) return [];

    let availablePanels: {
      id: number;
      type: "feed" | "event" | "amenities";
      data: any;
    }[] = [];

    if (announcements.length > 0) {
      availablePanels.push({ id: 1, type: "feed", data: announcements[0] });

      if (announcements.length > 1) {
        availablePanels.push({ id: 2, type: "feed", data: announcements[1] });
      }
    }

    const activeEvents = events.filter((ev) => ev.isCanceled === false);

    if (activeEvents.length > 0) {
      availablePanels.push({ id: 3, type: "event", data: activeEvents[0] });

      if (activeEvents.length > 1) {
        availablePanels.push({ id: 4, type: "event", data: activeEvents[1] });
      }
    }

    if (amenities.length) {
      for (let i = 0; i < amenities.length; i += chunkSize) {
        const chunk = amenities.slice(i, i + chunkSize);
        availablePanels.push({ id: 5 + i, type: "amenities", data: chunk });
      }
    }

    return availablePanels;
  }, [isLoading, announcements, events, amenities]);

  return (
    <div className="h-screen portrait:flex portrait:flex-col">
      <WeatherHeader bannerUrl={bannerUrl} weather={weather} />
      <div className="flex landscape:h-screen portrait:flex-1">
        <WeatherAside
          bannerUrl={bannerUrl}
          weather={weather}
          logoUrl={String(brandIcon)}
          brandBranchLink={brandBranchLink}
          brandName={brandName}
        />
        <main className="bg-pebbles flex-1 relative overflow-hidden">
          {panels.length > 0 && <Panels panels={panels} />}
        </main>
      </div>
      <PotraitFooter
        logoUrl={String(brandIcon)}
        brandBranchLink={brandBranchLink}
        brandName={brandName}
      />
    </div>
  );
};

export default LobbyScreen;
