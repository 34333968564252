import moment from "moment";
import { EXPIRATION_DATE } from "config";

export default function getRefetchInterval(): number | false | undefined {
  const expirationDate = localStorage.getItem(EXPIRATION_DATE);

  if (expirationDate) {
    const duration = moment(expirationDate).diff(moment(), "milliseconds");
    return duration;
  }

  return false;
}
