import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import MainWrapper from "components/MainWrapper";
import { IConfiguration } from "data/configuration";

import useGetConfiguration from "../hooks/useGetConfiguration";
import rg4js from "raygun4js";

export const ConfigurationContext = React.createContext<IConfiguration>({
  bannerUrl: null,
  displayName: null,
  message: "",
  propertyId: 0,
  version: null,
  viewPanels: [],
  brandBranchLink: null,
  brandIcon: null,
  brandName: null,
  type: null
});

const HomeContainer = () => {
  const history = useHistory();
  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  const onSuccess = (data: IConfiguration) => {
    if (data.message) {
      history.push("/admin");
    }

    if (data.version && currentVersion && currentVersion !== data.version) {
      window.location.reload();
    }

    setCurrentVersion(data.version);
  };

  const onError = (e: unknown) => {
    rg4js("send", e);
    /* localStorage.removeItem(DEVICE_CODE);
    localStorage.removeItem(ACCESS_TOKEN);
    history.push("/activation"); */
  };

  const { data } = useGetConfiguration(onSuccess, onError, 1000 * 60 * 30);

  if (data && !data.message) {
    return (
      <ConfigurationContext.Provider value={data}>
        <MainWrapper />
      </ConfigurationContext.Provider>
    );
  }

  return <p>loading...</p>;
};

export default HomeContainer;
