import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from "App";

type Props = { component: () => JSX.Element; path: string };

const PrivateRoute = ({ component: Component, path, ...rest }: Props) => {
  const { accessToken } = useContext(AuthContext);

  const isAuthed = Boolean(accessToken);

  return (
    <Route
      {...rest}
      path={path}
      render={() => {
        if (isAuthed) {
          return <Component />;
        } else {
          return <Redirect to="/activation" />;
        }
      }}
    />
  );
};

export default PrivateRoute;
