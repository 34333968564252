import React, { useContext } from "react";

import { DEVICE_CODE, ACCESS_TOKEN } from "config";
import { AuthContext } from "App";
import { RouteComponentProps } from "react-router-dom";

const Logout = ({ history }: RouteComponentProps) => {
  const { setAccessToken, setDeviceCode, setCodeResponse } = useContext(
    AuthContext
  );

  React.useEffect(() => {
    localStorage.removeItem(DEVICE_CODE);
    localStorage.removeItem(ACCESS_TOKEN);

    setAccessToken("");
    setDeviceCode("");
    setCodeResponse(null);

    history.replace("/");
  }, [setAccessToken, setDeviceCode, setCodeResponse, history]);

  return (
    <div className="h-screen flex items-center justify-center">
      <p>Logging out...</p>
    </div>
  );
};

export default Logout;
