import React from "react";

import { IAnnouncement } from "data/announcements";
import toLocalTime from "utils/toLocalTime";

import AvatarDetails from "components/AvatarDetails";
import Logo from "components/Logo";
import PageTitle from "components/PageTitle";

type Props = { data?: IAnnouncement };

const Announcement = ({ data }: Props) => {
  const { actorName, actorUri, textBody, timestamp, images } = data!;
  const [image] = images;

  const bodyLength = textBody.length;

  let textClass = "text-xl";
  if (bodyLength < 500) {
    textClass = "text-4xl";
  } else if (bodyLength < 1000) {
    textClass = "text-3xl";
  }

  return (
    <div className="landscape:h-screen portrait:h-full flex flex-col">
      <PageTitle title="Community Announcements" />
      <div className="px-24 pt-0">
        <div className="rounded-xl shadow-livly p-8 bg-white w-full flex flex-col overflow-hidden gap-4">
          <AvatarDetails
            avatarUri={actorUri}
            primary={actorName}
            secondary={toLocalTime(timestamp).fromNow()}
          />
          <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
            <div className="aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4">
              {image && (
                <img
                  className="object-cover shadow-lg rounded-lg"
                  src={image.uri}
                  alt=""
                />
              )}
            </div>
            <div className={`${image ? "sm:col-span-2" : "sm:col-span-3"}`}>
              <div className={`${textClass} whitespace-pre-line`}>
                <p>{textBody}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Logo />
    </div>
  );
};

export default Announcement;
