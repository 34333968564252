import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Redirect, RouteComponentProps } from "react-router-dom";
import copy from "copy-to-clipboard";

import { AuthContext } from "App";
import {
  DEVICE_CODE,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  EXPIRATION_DATE,
} from "config";
import { getDeviceCode, getAccessToken } from "data/activation";
import getRefetchInterval from "utils/getRefetchInterval";
import rg4js from "raygun4js";

const Activation = ({ history }: RouteComponentProps) => {
  const {
    deviceCode,
    accessToken,
    codeResponse,
    setAccessToken,
    setCodeResponse,
    setDeviceCode,
    setRefetchInterval,
  } = useContext(AuthContext);

  useEffect(() => {
    if (deviceCode && !codeResponse) {
      onReset();
    }
  }, [codeResponse, deviceCode]);

  useQuery("device-code", () => getDeviceCode(), {
    refetchInterval: 1000 * 60 * 5, //get new code every 5 minutes
    refetchOnWindowFocus: false,
    onSuccess: (response) => {
      setCodeResponse(response);
      setDeviceCode(response.device_code);

      localStorage.setItem(DEVICE_CODE, response.device_code);
    },
    onError: (e) => {
      rg4js("send", e);
    },
  });

  useQuery("access-token", () => getAccessToken(deviceCode), {
    enabled: deviceCode !== "",
    retry: 0,
    refetchOnWindowFocus: false,
    refetchInterval: 5000,
    onSuccess: (response) => {
      if (response) {
        const { data } = response;
        const { refresh_token, Expiraton_date, access_token } = data;

        if (refresh_token) {
          localStorage.setItem(REFRESH_TOKEN, refresh_token);
        }

        localStorage.setItem(EXPIRATION_DATE, Expiraton_date);
        localStorage.setItem(ACCESS_TOKEN, access_token);

        setAccessToken(access_token);
        const interval = getRefetchInterval();
        setRefetchInterval(interval);

        history.push("/");
      }
    },
    onError: (e) => {
      rg4js("send", e);
    },
  });

  const onCopy = () => {
    if (codeResponse) {
      copy(codeResponse.user_code);
    }
  };

  const onReset = () => {
    localStorage.removeItem(DEVICE_CODE);
    localStorage.removeItem(ACCESS_TOKEN);
    window.location.reload();
  };

  if (accessToken && deviceCode) {
    return <Redirect to="/" />;
  }

  if (codeResponse) {
    return (
      <div className="h-screen flex items-center justify-center bg-pebbles">
        <div className="p-12 shadow-livly rounded-xl bg-white">
          <div className="mb-6 flex justify-center">
            <img src="/assets/logo.svg" alt="livly view" />
          </div>
          <div className="mb-12">
            <h2 className="mb-4">To activate this device</h2>
            <ol className="list-decimal text-3xl pl-4">
              <li className="mb-4">
                Visit{" "}
                <a
                  href={codeResponse.verification_uri}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  {codeResponse.verification_uri}
                </a>{" "}
                on your computer or mobile device.
              </li>
              <li className="mb-4">
                When prompted, enter the Activation Code below.
              </li>
              <li>Return to this screen.</li>
            </ol>
          </div>
          <div>
            <p className="mb-2 text-3xl">Activation Code:</p>
            <button
              className="font-bold text-7xl cursor-pointer"
              onClick={onCopy}
            >
              {codeResponse.user_code}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Activation;
