import { useQuery } from "react-query";

import { DEVICE_CODE, ACCESS_TOKEN } from "config";
import { IConfiguration } from "data/configuration";
import useMakeAuthedRequest from "utils/api";

export default function useGetConfiguration(
  onSuccess: ((data: IConfiguration) => void) | null,
  onError: ((e: unknown) => void) | null,
  refetchInterval: number = 1000 * 60 // 1 minute interval
) {
  const makeRequest = useMakeAuthedRequest();

  const deviceCode = localStorage.getItem(DEVICE_CODE);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  const getConfiguration = async () => {
    const result = await makeRequest<IConfiguration>({
      url: `/livly/view/${deviceCode}/configuration`,
    });

    return result;
  };

  return useQuery("configuration", getConfiguration, {
    enabled: Boolean(deviceCode) && Boolean(accessToken),
    refetchInterval,
    refetchOnWindowFocus: false,
    retryDelay: 1000 * 30,
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: (e) => {
      if (onError) {
        onError(e);
      }
    },
  });
}
