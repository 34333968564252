import React from "react";

export interface CardProps {
  scribble?: boolean;
  padding?: "sm" | "md";
}

const Card: React.FC<CardProps> = ({ scribble, padding, children }) => {
  let p = "";

  switch (padding) {
    case "sm":
      p = "p-6";
      break;
    default:
      p = "p-16";
  }

  return (
    <div
      className={`relative ${
        scribble ? "mb-8" : ""
      } container mx-auto ${p} bg-white shadow-livly rounded-xl`}
    >
      <div className="overflow-hidden h-full flex flex-col">{children}</div>
      {scribble && (
        <div className="absolute" style={{ left: -50, bottom: -110 }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="290.615"
            height="177.968"
            viewBox="0 0 290.615 177.968"
          >
            <path
              id="Scribble_13"
              d="M506.974-643.74a397.862,397.862,0,0,1,75.806-98.783q13-12.334,27.082-23.461l-7.567-5.84-52.642,86.249c-4.169,6.833-8.662,13.587-11.844,20.955-1.532,3.545-2.747,6.973-1.694,10.853A13.108,13.108,0,0,0,541.8-646.2c6.869,4.283,13.995.118,20.045-3.589,6.779-4.156,13.2-8.953,19.608-13.646q22.106-16.176,42.966-33.965c4.107-3.5-1.476-10.909-5.84-7.569a48.779,48.779,0,0,0-16.931,23.474c-1.278,3.812,2.585,6.48,5.929,5.927,12.9-2.134,23.351-10.364,33.933-17.434,2.606-1.742,5.25-3.429,7.989-4.951a18.235,18.235,0,0,0,2.3-1.228c1.5-1.16.418,1.2-1.289-.923a1.327,1.327,0,0,1-.312-1.448q.31-1.477-.4.071a15.183,15.183,0,0,0-1.393,2.365,221.761,221.761,0,0,0-10.807,21.184c-.958,2.151-.86,5.472,1.729,6.594,9.462,4.1,17.787-1.372,25.471-6.623q12.677-8.662,25.284-17.43,24.821-17.266,49.354-34.944,13.99-10.082,27.884-20.294c2.1-1.54,3.142-4.181,1.729-6.594-1.209-2.066-4.484-3.279-6.592-1.729q-46.446,34.133-93.949,66.793c-4.441,3.053-8.833,6.21-13.371,9.115-2.781,1.779-7.438,4.9-10.948,3.383l1.729,6.594q2.793-6.271,5.952-12.368c2.114-4.057,4.624-7.912,6.721-11.967a7.876,7.876,0,0,0-3.169-10.857c-4.13-2.147-8.213.5-11.771,2.535-12.648,7.257-23.773,18.405-38.645,20.864l5.929,5.927a37.458,37.458,0,0,1,12.5-17.713l-5.84-7.569q-16.945,14.45-34.759,27.83-8.8,6.583-17.787,12.895c-2.891,2.026-5.805,4.023-8.833,5.838-2.118,1.27-5.231,3.668-7.71,3.735a3.276,3.276,0,0,1-3.028-2.12c-.765-2.166,1.451-5.615,2.519-7.719,3.325-6.551,7.392-12.737,11.216-19L583.173-722l27.443-44.963a4.809,4.809,0,0,0-1.241-6.216c-2.066-1.546-4.387-1.158-6.326.376a406.022,406.022,0,0,0-85.923,93.492q-9.923,14.93-18.475,30.7c-2.961,5.451,5.358,10.321,8.323,4.863Z"
              transform="matrix(0.985, 0.174, -0.174, 0.985, -601.827, 675.918)"
              fill="#262b2b"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Card;
