import axios from "axios";

import { ACCESS_TOKEN, BASE_API_URL, DEVICE_CODE } from "config";

export interface IWeather {
  hourly: IWeatherHour[];
  daily: {
    code: number;
    icon: string;
    high: number;
    low: number;
    dateTime: string;
  };
}

export interface IWeatherHour {
  icon: string;
  temperature: number;
  dateTime: string;
  code: number;
}

export const getWeather = async () => {
  const deviceCode = localStorage.getItem(DEVICE_CODE);
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  const result = await axios.get<IWeather>(
    `${BASE_API_URL}/livly/view/${deviceCode}/weather`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return result.data;
};
