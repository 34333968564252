import React from "react";

import { IAmenity } from "data/amenities";
import toLocalTime from "utils/toLocalTime";

import Card from "components/Card";
import Logo from "components/Logo";
import PageTitle from "components/PageTitle";

type Props = { data: IAmenity[] };

const Amenities = ({ data }: Props) => {
  return (
    <div className="landscape:h-screen portrait:h-full flex flex-col">
      <PageTitle title="Amenities" />
      <div className="p-24 pt-0 overflow-auto flex flex-col max-h-full">
        {data.map((amenity) => (
          <AmenityItem key={amenity.amenitySpaceId} amenity={amenity} />
        ))}
      </div>
      <Logo />
    </div>
  );
};

function AmenityItem({ amenity }: { amenity: IAmenity }) {
  const { images, name, slots, bookingAvailabilityType } = amenity;
  const [image] = images;
  const hasSlots = slots.length > 0;

  const renderTimeDisplay = () => {
    if (bookingAvailabilityType === "Open") {
      return <p className="text-center">{amenity.description}</p>;
    } else {
      if (hasSlots) {
        return (
          <>
            {slots.map((slot) => (
              <div key={slot.startTime} className="flex justify-between mb-2">
                <p className="body2">
                  {toLocalTime(slot.startTime).format(
                    bookingAvailabilityType === "Overnight"
                      ? "dddd MMMM D, YYYY"
                      : "h:mma"
                  )}
                </p>
                <p className="body2">{slot.availableSlotText}</p>
              </div>
            ))}
          </>
        );
      } else {
        return <p className="text-center body2">Unavailable</p>;
      }
    }
  };

  return (
    <div className="mb-8" key={amenity.amenitySpaceId}>
      <Card padding="sm">
        <div className="flex">
          <img
            className="rounded-xl flex-shrink-0 mr-10 w-5/12 object-cover"
            src={image?.uri}
            alt={name}
            style={{ height: 180 }}
          />
          <div className="w-7/12">
            <h4 className="text-center mb-5">{name}</h4>
            {renderTimeDisplay()}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Amenities;
