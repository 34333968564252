import { library } from "@fortawesome/fontawesome-svg-core";
import {
  IconDefinition,
  fad,
  faThunderstorm,
  faSun,
  faCloudsSun,
  faSunCloud,
  faCloudSunRain,
  faMoon,
  faTemperatureHot,
  faWind,
  faFog,
  faSmog,
  faClouds,
  faTornado,
  faSmoke,
  faCloudDrizzle,
  faCloudShowers,
  faCloudShowersHeavy,
  faCloudRain,
  faCloudSnow,
  faCloudHail,
  faCloudSleet,
  faSnowflakes,
  faSnowBlowing,
  faTemperatureFrigid,
  faCloudMoonRain,
  faCloudsMoon,
  faThunderstormSun,
  faSunDust
} from "@fortawesome/pro-duotone-svg-icons";

import { fal, faMapMarkerAlt, faClock } from "@fortawesome/pro-light-svg-icons";

library.add(
  fad,
  faSun,
  faThunderstorm,
  faCloudsSun,
  faSunCloud,
  faCloudSunRain,
  faMoon,
  faTemperatureHot,
  faWind,
  faFog,
  faSmog,
  faClouds,
  faTornado,
  faSmoke,
  faCloudDrizzle,
  faCloudShowers,
  faCloudShowersHeavy,
  faCloudRain,
  faCloudSnow,
  faCloudHail,
  faCloudSleet,
  faSnowflakes,
  faSnowBlowing,
  faTemperatureFrigid,
  faCloudMoonRain,
  faCloudsMoon,
  faThunderstormSun,
  faSunDust,
  fal,
  faMapMarkerAlt,
  faClock
);

type IconStyle = React.CSSProperties & TFontAwesomeProperties;

export const bartBartStyle: IconStyle = {
  "--fa-primary-color": "#FFC747",
  "--fa-secondary-color": "#FFC747",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export const astroBartStyle: IconStyle = {
  "--fa-primary-color": "#c8c8c8",
  "--fa-secondary-color": "#FFC747",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export const bartAstroStyle: IconStyle = {
  "--fa-primary-color": "#FFC747",
  "--fa-secondary-color": "#c8c8c8",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export const astroSonicStyle: IconStyle = {
  "--fa-primary-color": "#c8c8c8",
  "--fa-secondary-color": "#5A88ED",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export const sonicAstroStyle: IconStyle = {
  "--fa-primary-color": "#5A88ED",
  "--fa-secondary-color": "#c8c8c8",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export const bartStyle: IconStyle = {
  "--fa-primary-color": "#FFC747",
  "--fa-secondary-color": "#FFC747",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export const sonicStyle: IconStyle = {
  "--fa-primary-color": "#5A88ED",
  "--fa-secondary-color": "#5A88ED",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export const astroStyle: IconStyle = {
  "--fa-primary-color": "#c8c8c8",
  "--fa-secondary-color": "#c8c8c8",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1
};

export interface IconSet {
  icon: IconDefinition;
  style: IconStyle;
}

export const IconCodeMap: Record<number, IconSet> = {
  0: { icon: faTornado, style: astroStyle },
  3: { icon: faThunderstorm, style: astroBartStyle },
  4: { icon: faThunderstorm, style: astroBartStyle },
  5: { icon: faCloudSleet, style: astroSonicStyle },
  6: { icon: faCloudSleet, style: astroSonicStyle },
  7: { icon: faCloudSleet, style: astroSonicStyle },
  8: { icon: faCloudShowersHeavy, style: astroSonicStyle },
  9: { icon: faCloudDrizzle, style: astroSonicStyle },
  10: { icon: faCloudShowersHeavy, style: astroSonicStyle },
  11: { icon: faCloudShowers, style: astroSonicStyle },
  12: { icon: faCloudShowers, style: astroSonicStyle },
  13: { icon: faCloudSnow, style: astroSonicStyle },
  14: { icon: faCloudSnow, style: astroSonicStyle },
  15: { icon: faSnowBlowing, style: sonicAstroStyle },
  16: { icon: faCloudSnow, style: astroSonicStyle },
  17: { icon: faCloudHail, style: astroSonicStyle },
  18: { icon: faCloudShowersHeavy, style: astroSonicStyle },
  19: { icon: faSunDust, style: astroBartStyle },
  20: { icon: faFog, style: astroStyle },
  21: { icon: faSunDust, style: astroBartStyle },
  22: { icon: faSmoke, style: astroStyle },
  23: { icon: faWind, style: astroStyle },
  24: { icon: faWind, style: astroStyle },
  25: { icon: faTemperatureFrigid, style: sonicAstroStyle },
  26: { icon: faClouds, style: astroStyle },
  27: { icon: faCloudsMoon, style: astroSonicStyle },
  28: { icon: faCloudsSun, style: astroBartStyle },
  29: { icon: faCloudsMoon, style: astroSonicStyle },
  30: { icon: faSunCloud, style: astroBartStyle },
  31: { icon: faMoon, style: sonicStyle },
  32: { icon: faSun, style: bartBartStyle },
  33: { icon: faMoon, style: sonicStyle },
  34: { icon: faSun, style: bartStyle },
  35: { icon: faCloudHail, style: astroSonicStyle },
  36: { icon: faTemperatureHot, style: bartAstroStyle },
  37: { icon: faThunderstorm, style: astroBartStyle },
  38: { icon: faThunderstormSun, style: astroBartStyle },
  39: { icon: faCloudSunRain, style: astroBartStyle },
  40: { icon: faCloudShowers, style: astroSonicStyle },
  41: { icon: faSnowflakes, style: sonicStyle },
  42: { icon: faCloudSnow, style: astroSonicStyle },
  43: { icon: faCloudSnow, style: astroSonicStyle },
  45: { icon: faCloudMoonRain, style: astroSonicStyle },
  46: { icon: faCloudSnow, style: astroSonicStyle },
  47: { icon: faThunderstorm, style: astroBartStyle }
};

type TFontAwesomeProperties = {
  "--fa-primary-color"?: string;
  "--fa-secondary-color"?: string;
  "--fa-primary-opacity"?: number;
  "--fa-secondary-opacity"?: number;
};
