import React, { useState, createContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";

import PrivateRoute from "components/PrivateRoute";

import Logout from "views/Logout";
import Home from "views/Home";
import Activation from "views/Activation";
import Admin from "views/Admin";

import { ACCESS_TOKEN, DEVICE_CODE } from "config";
import "./icons";
import "utils/axios";
import getRefetchInterval from "utils/getRefetchInterval";
import { ICodeResponse } from "data/activation";

const queryClient = new QueryClient();

interface IAuth {
  deviceCode: string;
  accessToken: string;
  codeResponse: ICodeResponse | null;
  refetchInterval: number | false | undefined;
  setCodeResponse: (reponse: ICodeResponse | null) => void;
  setDeviceCode: (code: string) => void;
  setAccessToken: (token: string) => void;
  setRefetchInterval: (time: number | false | undefined) => void;
}

export const AuthContext = createContext<IAuth>({
  deviceCode: "",
  accessToken: "",
  codeResponse: null,
  refetchInterval: false,
  setCodeResponse: () => {},
  setDeviceCode: () => {},
  setAccessToken: () => {},
  setRefetchInterval: () => {},
});

function App() {
  const [refetchInterval, setRefetchInterval] = useState<
    number | false | undefined
  >(getRefetchInterval());
  const [deviceCode, setDeviceCode] = useState(
    localStorage.getItem(DEVICE_CODE) || ""
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(ACCESS_TOKEN) || ""
  );
  const [codeResponse, setCodeResponse] = useState<ICodeResponse | null>(null);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthContext.Provider
        value={{
          accessToken,
          deviceCode,
          codeResponse,
          refetchInterval,
          setCodeResponse,
          setDeviceCode,
          setAccessToken,
          setRefetchInterval,
        }}
      >
        <Router>
          <Switch>
            <Route path="/logout" component={Logout} />
            <Route path="/activation" component={Activation} />
            <PrivateRoute path="/admin" component={Admin} />
            <PrivateRoute path="/" component={Home} />
          </Switch>
        </Router>
      </AuthContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
