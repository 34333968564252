import React, { useContext } from "react";
import LobbyScreen from "./LobbyScreen";
import PackageScreen from "./PackageScreen";
import { ConfigurationContext } from "views/Home";
import { ViewTypeEnum } from "data/configuration";

const Home = () => {
  const { type } = useContext(ConfigurationContext);

  return type === ViewTypeEnum.Lobby ? (
    <LobbyScreen />
  ) : type === ViewTypeEnum.Package ? (
    <PackageScreen />
  ) : (
    <></>
  );
};

export default Home;
