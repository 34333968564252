import React, { useContext } from "react";
import { useQuery } from "react-query";

import { ConfigurationContext } from "views/Home";

import { getWeather } from "data/weather";

import rg4js from "raygun4js";
import { WeatherHeader } from "./WeatherHeader";
import { WeatherAside } from "./WeatherAside";
import PackagePanels from "./PackagePanels";
import { PotraitFooter } from "./PotraitFooter";
import { getPackages } from "data/packages";

export type PackageProps = {
  name: string;
  total: number;
};
const PackageScreen = () => {
  const { bannerUrl, brandBranchLink, brandName, brandIcon } =
    useContext(ConfigurationContext);

  const { data: weather } = useQuery("view-weather", getWeather, {
    refetchInterval: 1000 * 60 * 31,
    refetchOnWindowFocus: false,
    retryDelay: 1000 * 30,
    onError: (e) => {
      rg4js("send", e);
    }
  });
  const { data: packages, isLoading } = useQuery("view-packages", getPackages, {
    refetchInterval: 1000 * 60 * 31,
    refetchOnWindowFocus: false,
    retryDelay: 1000 * 30,
    onError: (e) => {
      rg4js("send", e);
    }
  });

  const data = React.useMemo(() => {
    if (isLoading) return [];
    let updateData: PackageProps[] = [];
    if (packages?.byUnit) {
      updateData = packages?.byUnit
        ? Object.keys(packages.byUnit).map((key: any) => {
            return { name: `Unit ${key}`, total: packages.byUnit[key] };
          })
        : [];
    }
    return updateData;
  }, [isLoading, packages]);

  const panels = React.useMemo(() => {
    if (isLoading) return [];

    let availablePanels: {
      id: number;
      type: "package";
      data: any;
    }[] = [];

    if (data.length) {
      for (let i = 0; i < data.length; i += 60) {
        const chunk = data.slice(i, i + 60);
        availablePanels.push({ id: 62 + i, type: "package", data: chunk });
      }
    }

    return availablePanels;
  }, [isLoading, data]);

  return (
    <div className="h-screen portrait:flex portrait:flex-col">
      <WeatherHeader bannerUrl={bannerUrl} weather={weather} />
      <div className="flex landscape:h-screen portrait:flex-1">
        <WeatherAside
          bannerUrl={bannerUrl}
          weather={weather}
          logoUrl={String(brandIcon)}
          brandBranchLink={brandBranchLink}
          brandName={brandName}
        />

        <main className="bg-pebbles flex-1 relative overflow-hidden">
          {panels.length > 0 && <PackagePanels panels={panels} />}
        </main>
      </div>
      <PotraitFooter
        logoUrl={String(brandIcon)}
        brandBranchLink={brandBranchLink}
        brandName={brandName}
      />
    </div>
  );
};

export default PackageScreen;
