import axios from "axios";

import { ACCESS_TOKEN, BASE_API_URL } from "config";

export interface IUser {
  invite_confirmed: boolean;
  id: string;
  client_id: number;
  first_name: string;
  last_name: string;
  email: string;
}

export const getUser = async () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  const result = await axios.get<{ Data: IUser }>(
    `${BASE_API_URL}/v2/AdminPortal/user/me`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  );

  return result.data.Data;
};
